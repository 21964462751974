<script>
import axios from "axios";
import Currencies from "@/core/Currencies";

export default {
  props: {
    active_tab: {
      type: String,
      required: true,
    },
    positions: {
      type: Array,
      required: true
    },
    disable_tab_change: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    month_label() {
      return this.item ? this.item.month_label : "";
    },
    position_label() {
      return this.position
          ? this.position
              .split("_")
              .map(i => i.charAt(0).toUpperCase() + i.slice(1, i.length))
              .join(' ')
          : "";
    },
    details_filtered_position() {
      return (this.details || []).filter(item => {
        return (
            (this.filters.platform ? item.platform.includes(this.filters.platform) : true) &&
            (this.filters.position ? item.position === this.filters.position : true) &&
            (this.filters.order_number ? String(item.order_number).includes(this.filters.order_number) : true) &&
            (this.filters.quantity ? item.quantity === this.filters.quantity : true) &&
            (this.filters.departure ?
                    (item.departure && (
                        item.departure.name.toLowerCase().includes(this.filters.departure.toLowerCase()) ||
                        item.departure.code.includes(this.filters.departure)
                    )) : true
            ) &&
            (this.filters.destination ?
                    (item.destination && (
                        item.destination.name.toLowerCase().includes(this.filters.destination.toLowerCase()) ||
                        item.destination.code.includes(this.filters.destination)
                    )) : true
            ) &&
            (this.filters.product ?
                    (item.product && (
                        item.product.hc_code.includes(this.filters.product) ||
                        item.product.name.toLowerCase().includes(this.filters.product.toLowerCase())
                    )) : true
            ) &&
            (this.filters.date ? item.date.includes(this.filters.date) : true) &&
            (this.filters.company ? item.company && item.company.name.includes(this.filters.company) : true) &&
            (this.filters.manager ? item.user && (item.user.first_name + " " + item.user.last_name).includes(this.filters.manager) : true)
        );
      });
    },
    total_wagons_count() {
      return this.details_filtered_position.filter(item => item.child_type !== 'container_order').reduce((total, item) => total + item.quantity, 0);
    },
    total_containers_count() {
      return this.details_filtered_position
          .filter(({child_type}) => child_type === 'container_order')
          .reduce((total, {container_types}) =>
                  total + container_types.reduce((sum, {quantity}) => sum + quantity, 0),
              0);
    },
    total_agreed_rate() {
      return this.details_filtered_position.reduce((total, item) => {
        return total + parseFloat(item.total_agreed_rate)
      }, 0);
    },
    total_expense() {
      return this.details_filtered_position.reduce((total, item) => {
        return total + parseFloat(item.total_expense)
      }, 0);
    },
  },
  data() {
    return {
      showModal: false,
      position: null,
      item: null,

      Currencies: Currencies,
      details: [],
      is_loading: false,

      filters: {
        platform: '',
        position: '',
        order_number: '',
        quantity: '',
        departure: '',
        destination: '',
        product: '',
        agreed_rate: '',
        expense: '',
        date: '',
        company: '',
        manager: ''
      }
    }
  },
  methods: {
    async openModal(position, item) {
      this.details = []
      this.position = position
      this.item = item
      this.showModal = true
      this.filters = {
        platform: '',
        position: this.active_tab === 'all' ? '' : this.active_tab ? this.active_tab : '',
        order_number: '',
        quantity: '',
        departure: '',
        destination: '',
        product: '',
        agreed_rate: '',
        expense: '',
        date: '',
        company: '',
        manager: ''
      }
      await this.getDetails()
    },
    async getDetails() {
      try {
        this.is_loading = true
        const platforms = (this.$route.query.platforms || '').split(',').filter(i => i !== '')
        let response = await axios.get('/order/sales_plan_detail/', {
          params: {
            year: this.item.year,
            month: this.item.month,
            platforms: platforms.length > 0 ? platforms : undefined,
          }
        })
        this.details = response.data
        this.is_loading = false
      } catch {
        this.is_loading = false
        alert("Error")
      }
    },
    getOrderLink(child_type, order_number, platform) {
      let suffix = platform.toLowerCase() === 'ca' ? 'https://ca.interrail.uz/' : '/'
      switch (child_type) {
        case "container_order":
          return `${suffix}orders/container/view/${order_number}/`
        case "wagon_order":
          return `${suffix}orders/wagon/view/${order_number}/`
        case "wagon_empty_order":
          return `${suffix}orders/empty-wagon/view/${order_number}/`
        default:
          return "/"
      }
    },
    formatPrice(price) {
      return parseFloat(price || '0').toLocaleString(undefined, {
        minimumFractionDigits: 2, maximumFractionDigits: 2
      })
    },
    getConvertedValue(value, exchange_rate) {
      try {
        return parseFloat(value || '0') * parseFloat(1 / (exchange_rate || '0'))
      } catch {
        return '1'
      }
    },
  },
  watch: {
    showModal: {
      async handler(val) {
        if (val) {
          await this.$router.push({
            query: {
              ...this.$route.query,
              show_details: true
            }
          })
        } else {
          let query = {...this.$route.query}
          delete query.show_details
          await this.$router.push({
            query: query
          })
        }
      },
      immediate: true,
    },
    "$route.query.show_details": {
      handler(val) {
        if (val) {
          if (this.position && this.item) {
            this.showModal = true
          }
        } else {
          this.showModal = false
        }
      },
      immediate: true,
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" fullscreen centered hide-footer>

    <template v-slot:title>
      <div class="d-flex gap-2">
        <h2 class="mb-0">
          <span @click="showModal = false" class="badge bg-soft-dark text-body cursor-pointer">
            <i class="mdi mdi-arrow-left"></i>
          </span>
        </h2>

        <h2 class="mb-0">
          <span class="badge badge-gradient-info">
            {{ `${position_label} - ${month_label}, ${item ? item.year : '****'}` }}
          </span>
        </h2>
      </div>
    </template>

    <div class="row">
      <div class="col-lg-3">
        <div class="card">
          <div class="card-body d-flex gap-3 align-items-center">
            <div class="avatar-sm">
              <div
                  class="avatar-title border bg-soft-primary border-primary border-opacity-25 rounded-2 fs-17 text-primary">
                W
              </div>
            </div>
            <div class="flex-grow-1">
              <h5 class="fs-15">{{ total_wagons_count }}</h5>
              <p class="mb-0 text-muted">Wagons quantity</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card">
          <div class="card-body d-flex gap-3 align-items-center">
            <div class="avatar-sm">
              <div
                  class="avatar-title border bg-soft-secondary border-secondary border-opacity-25 rounded-2 fs-17 text-secondary">
                C
              </div>
            </div>
            <div class="flex-grow-1">
              <h5 class="fs-15">{{ total_containers_count }}</h5>
              <p class="mb-0 text-muted">Containers quantity</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card">
          <div class="card-body d-flex gap-3 align-items-center">
            <div class="avatar-sm">
              <div
                  class="avatar-title border bg-soft-success border-success border-opacity-25 rounded-2 fs-17 text-success">
                <i class="mdi mdi-cash-plus"></i>
              </div>
            </div>
            <div class="flex-grow-1">
              <h5 class="fs-15">$ {{ formatPrice(total_agreed_rate) }}</h5>
              <p class="mb-0 text-muted">Total agreed rate</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card">
          <div class="card-body d-flex gap-3 align-items-center">
            <div class="avatar-sm">
              <div
                  class="avatar-title border bg-soft-danger border-danger border-opacity-25 rounded-2 fs-17 text-danger">
                <i class="mdi mdi-cash-minus"></i>
              </div>
            </div>
            <div class="flex-grow-1">
              <h5 class="fs-15">$ {{ formatPrice(total_expense) }}</h5>
              <p class="mb-0 text-muted">Total expenses</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
        <tr class="align-middle">
          <th scope="col">#</th>
          <th scope="col">Platform</th>
          <th scope="col">Position</th>
          <th scope="col">Order number</th>
          <th scope="col">Quantity</th>
          <th scope="col">Departure</th>
          <th scope="col">Destination</th>
          <th scope="col">Product</th>
          <th scope="col">Agreed rate</th>
          <th scope="col">Expense</th>
          <th scope="col">Date</th>
          <th scope="col">Company</th>
          <th scope="col">Manager</th>
        </tr>
        <tr class="align-middle">
          <th scope="col">#</th>
          <th scope="col">
            <div>
              <select v-model="filters.platform" class="form-select form-select-sm ">
                <option value="" selected>All</option>
                <option value="ag">AG</option>
                <option value="ca">CA</option>
              </select>
            </div>
          </th>
          <th scope="col">
            <div>
              <select v-model="filters.position" class="form-select form-select-sm">
                <option value="" selected>All</option>
                <option value="block_train">Block train</option>
                <option value="multi_modal">Multi modal</option>
                <option value="rail_forwarder">Rail forwarder</option>
              </select>
            </div>
          </th>
          <th scope="col">
            <div>
              <input v-model="filters.order_number" class="form-control form-control-sm" type="number"
                     placeholder="Order number"/>
            </div>
          </th>
          <th scope="col">
            <div>
              <input v-model="filters.quantity" class="form-control form-control-sm" type="number"
                     placeholder="Quantity"/>
            </div>
          </th>
          <th scope="col">
            <div>
              <input v-model="filters.departure" class="form-control form-control-sm" type="text"
                     placeholder="Departure"/>
            </div>
          </th>
          <th scope="col">
            <div>
              <input v-model="filters.destination" class="form-control form-control-sm" type="text"
                     placeholder="Destination"/>
            </div>
          </th>
          <th scope="col">
            <div>
              <input v-model="filters.product" class="form-control form-control-sm" type="text"
                     placeholder="Product"/>
            </div>
          </th>
          <th scope="col">
            <div>
              <input disabled class="form-control form-control-sm" type="number"
                     placeholder="Agreed rate"/>
            </div>
          </th>
          <th scope="col">
            <div>
              <input disabled class="form-control form-control-sm" type="number"
                     placeholder="Expense"/>
            </div>
          </th>
          <th scope="col">
            <div>
              <input v-model="filters.date" class="form-control form-control-sm" type="date" placeholder="Date"/>
            </div>
          </th>
          <th scope="col">
            <div>
              <input v-model="filters.company" class="form-control form-control-sm" type="text"
                     placeholder="Company"/>
            </div>
          </th>
          <th scope="col">
            <div>
              <input v-model="filters.manager" class="form-control form-control-sm" type="text"
                     placeholder="Manager"/>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(detail, index) in details_filtered_position" :key="`detail_${index}`">
          <tr class="align-middle">
            <th scope="row" class="text-start">{{ index + 1 }}</th>
            <td class="text-uppercase">{{ detail.platform }}</td>
            <td style="min-width: 140px" class="text-capitalize">{{ detail.position.split('_').join(' ') }}</td>
            <td style="min-width: 140px">
              <a :href="getOrderLink(detail.child_type, detail.order_number, detail.platform)" target="_blank">
                  <span class="badge badge-soft-secondary fs-10">
                    {{ detail.order_number }}
                  </span>
              </a>
            </td>
            <td>
              <div v-if="detail.child_type === 'container_order'" class="d-flex flex-wrap" style="max-width: 150px">
                  <span v-for="type in detail.container_types" :key="`type_${type.type}`"
                        class="badge badge-soft-secondary fs-10">
                    {{ type.quantity }} x {{ type.type }}
                  </span>
              </div>
              <div v-else>
                  <span class="badge badge-soft-primary fs-10">
                    {{ detail.quantity }} x W
                  </span>
              </div>
            </td>
            <td style="min-width: 140px">
              <i class="bx bx-map text-success"></i>
              {{
                detail.departure
                    ? detail.departure.name + " (" + detail.departure.code + ")"
                    : '-'
              }}
            </td>
            <td style="min-width: 140px">
              <i class="bx bx-map text-danger"></i>
              {{
                detail.destination
                    ? detail.destination.name + " (" + detail.destination.code + ")"
                    : '-'
              }}
            </td>
            <td style="max-width: 140px" class="text-truncate">
              <div v-if="detail.product" v-b-tooltip.hover
                   :title="`${detail.product.name} (${detail.product.hc_code})`" class="text-truncate">
                ({{ detail.product.hc_code }}) {{ detail.product.name }}
              </div>
            </td>
            <td style="min-width: 140px">
              <span v-b-tooltip.hover
                    :title="`Currency: ${detail.currency}. Rate: ${formatPrice(detail.exchange_rate)}`">
                $ {{ formatPrice(detail.total_agreed_rate) }}
              </span>
            </td>
            <td style="min-width: 140px">
              $ {{ formatPrice(detail.total_expense) }}
            </td>
            <td>{{ detail.date }}</td>
            <td style="min-width: 140px">{{ detail.company ? detail.company.name : '-' }}</td>
            <td style="min-width: 140px">
              {{
                detail.user
                    ? detail.user.username
                    : "-"
              }}
            </td>
          </tr>
        </template>
        <tr v-if="is_loading">
          <td colspan="15">
            Loading...
            <b-spinner variant="primary" style="max-height: 15px; max-width: 15px"></b-spinner>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </b-modal>
</template>

<style scoped></style>